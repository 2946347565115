import {
  parseInvalidDate,
  parseInvalidDateAndFormat,
  convertTwentyFourHrToTwelveHr,
  formatDateTime,
} from 'common/utilities/dayJsUtils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SEARCH_STORE_CONSTANTS from 'organisms/SearchStore/AU/Constants/SearchStoreConstants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';
import CONTEXT_REDUCER_MAPPING_KEY from 'common/constants/ContextReducerKeys';
import { updateStoreWithDisposition, createBasketsAction } from '@kfc-global/react-shared/redux/Actions/CartAction';
import {
  createBasketData,
  getAndCheck,
  getDataLayer,
  getDispositionTypeOnAPIFormat,
  getEmbeddedErrorMessageAnalytic,
  getFullAddressDetails,
  getValueOrDefaultArray,
  getValueOrDefaultObject,
  getValueOrEmptyString,
  isEmptyEvery,
  isEmptyObject,
  recursiveOrCheck,
  isPickupDisposition,
  isDeliveryDisposition,
  isCateringDisposition,
  checkTimeDuration,
  isSupportedTenantCode,
  getUrlImagePath,
  isSafari,
} from 'common/utilities/utils';
import { ChannelManager } from 'common/manager';
import SEARCHSTORE_MODULE_DATA from '../Data/Defaults';
import ImageComponent from 'atoms/Image';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import {
  ASAP_TIMING,
  LOCAL_STORAGE_KEYS,
  ROUTE_URL,
  KMS,
  DAYS,
  EVERYDAY,
  DATE_FORMATS,
  PICK_UP,
  Config,
  SEO_OPTIONS,
  DISPOSITION_KEYS,
  FIND_KFC,
  ASAP,
  BROWSE_MENU,
  IMAGE_SIZE_CONSTANTS,
  UNIT_BY_ADDRESS,
} from 'common/constants/SharedConstants';
import {
  getStoreStatusForTimeSaga,
  setStoresFavourite,
  setStoresUnFavourite,
  orderReadyDateTimeUpdate,
} from '@kfc-global/react-shared/redux/Actions/StoresAction';
import { setLocalStorage } from '../Presentation/DispositionModuleUtils/DispositionModuleUtils';
import { SAVED } from 'organisms/SearchStore/AU/Config/searchStore.config';
import { setLocalisationFinishAnalyticsData } from 'common/utilities/analyticsUtils';
import parse from 'html-react-parser';
import { login } from 'common/utilities/LoginUtils';
import { getDateTimeList, successCallbackHandler } from 'organisms/ScheduleOrder/ScheduleOrderUtils/ScheduleOrderUtils';
import { resetMenuFulfillmentData } from '@kfc-global/react-shared/redux/Actions/MenuAction';
import { STORE_CLOSING_SOON_MODAL_TIMING } from '@kfc-global/react-shared/config/config.constants';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { filterStoreBasedOnPolygon } from 'organisms/SearchStore/AU/SearchStoreUtilities/geoUtils';

const { GreenTickImage } = IMAGE_PATH;
const { MENU_PAGE, CATERING_PAGE } = ROUTE_URL;
const { DELIVERY } = DISPOSITION_KEYS;
const {
  CURRENT_STORE_INFO_STORAGE,
  ANALYTICS_LOCALISATION_SIGNIN,
  DISPOSITION_TYPE,
  STORE_INFO,
  DISPOSITION_NEW,
  CONFIG_SEARCH_SCREEN,
} = LOCAL_STORAGE_KEYS;
const {
  SCHEDULE_ORDER_DATA,
  SEARCH_ORDER_DATA,
  CURRENT_STORE_INFO,
  DISPOSITION_METHOD,
  SET_FORCERELOAD,
  SHOW_LOCATION_CONFIRM_MODAL,
  FAV_STORE_NOT_IN_RADIUS,
  REORDER_MODAL_OPEN,
  REORDER_STORE_CHANGED,
  ORDER_SUMMARY_MODAL_CLOSE,
  SET_ORDER_NOW,
  SET_SCHEDULE_ORDER,
  SET_SCHEDULE_ORDER_REORDER,
  SET_ORDER_NOW_REORDER,
  CHANGE_SCHEDULE_MODE,
  SCHEDULE_ORDER_CLICKED,
  SHOW_SCHEDULE_DISPOSITION,
} = CONTEXT_REDUCER_MAPPING_KEY;
const { LIST_TEXT, MAP_TEXT, BROWSE_MENU_TEXT, START_ORDER, TRADING_HRS, DELIVERY_HRS } = TRANSLATE_MAPPING_KEY;
const { MapImage, Union } = IMAGE_PATH;
const {
  TAKEAWAY_UNAVAILABLE_WITH_CTA,
  TAKEAWAY_UNAVAILABLE,
  DINEIN_UNAVAILABLE,
  TAKE_AWAY_SERVICE,
  DINE_IN_SERVICE,
  STORE_STATUS_OPEN,
  DELIVERY_SERVICE,
  DELIVERY_UNAVAILABLE,
  LOCATION_WARNING_MESSAGE,
  LOCALISATION_ERROR,
  API_ERROR,
  PHONE_NUMBER,
  STORE_MESSAGE,
  GEOLOCATIOM_TEXT,
  PROMPT_TEXT,
  SELECTED_TIME,
  SUBPREMISE,
  SELECTED_FAV_ADDRESS,
  SELECTED_LINE_ADDRESS_VAL,
  FORMATTED_DELIVERY_ADDRESS,
  IS_FAV_ADDRESS_SELECTED,
  STREET_NUMBER_FILTER,
} = SEARCH_STORE_CONSTANTS;
const { FINDAKFC_ITEMS } = IMAGE_SIZE_CONSTANTS;

/**
 * Method to set analytics items in the data layer object
 */
export const setAnalyticsData = async analyticsData => {
  const analyticsItems = [];
  analyticsItems.push(analyticsData);
  await AnalyticsService.registerEvent(
    undefined,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};
export const setLocalisedSignInAnalyticsData = async () => {
  await AnalyticsService.registerEvent(
    events?.localisationSignIn?.name,
    {
      '%event%': 'localisationSignIn',
    },
    getDataLayer,
  );
};
export const setOrderNowAnalyticsData = async () => {
  await AnalyticsService.registerEvent(
    events?.orderNow?.name,
    {
      '%event%': 'orderNow',
    },
    getDataLayer,
  );
};
export const setOrderLaterAnalyticsData = async () => {
  const analyticsItems = [];
  analyticsItems.push({
    event: 'orderForLater',
  });
  await AnalyticsService.registerEvent(
    undefined,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};
export const localisationViewAnalyticsData = async (showMap, dispositionType) => {
  await AnalyticsService.registerEvent(
    events?.localisationViewPWA?.name,
    {
      '%event%': 'localisationView',
      '%viewSelected%': showMap ? translateWithI18Next('list') : translateWithI18Next('map'),
      '%disposition%': dispositionType,
    },
    getDataLayer,
  );
};
export const setUseMyLocationAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events?.useMyLocation?.name,
    {
      data: {
        event: 'useMyLocation',
      },
    },
    getDataLayer,
  );
};

export const singInClickhandler = event => {
  event.stopPropagation();
  event.preventDefault();
  localStorage.setItem(ANALYTICS_LOCALISATION_SIGNIN, JSON.stringify(true));
  setLocalisedSignInAnalyticsData();
  login();
};

export const findkfcSeoObj = param => {
  return param.reduce((obj, item) => {
    const { fields } = item;
    if (FIND_KFC === fields.title) {
      obj = fields.templateData;
    }
    return obj;
  }, {});
};
export const getFindKFCContentData = ({ title = '', body = [], page = '' }) => {
  const items = body?.map(item => item.fields);
  if (!isEmpty(items) && FIND_KFC === title) {
    const headline = items[0].headline;
    const subHeadline = items[0].subHeadline;
    const desktopImage = getUrlImagePath(
      items[0]?.image?.fields?.file?.url,
      FINDAKFC_ITEMS.FINDAKFC_IMAGE_HEIGHT_DESKTOP,
      FINDAKFC_ITEMS.FINDAKFC_IMAGE_WIDTH_DESKTOP,
    );
    const mobileImage = getUrlImagePath(
      items[0]?.image?.fields?.file?.url,
      FINDAKFC_ITEMS.FINDAKFC_IMAGE_HEIGHT_MOBILE,
      FINDAKFC_ITEMS.FINDAKFC_IMAGE_WIDTH_MOBILE,
    );
    return {
      pageTitle: title,
      headLine: headline,
      subHeadLine: subHeadline,
      desktopImage: desktopImage,
      mobileImage: mobileImage,
    };
  }
};
// method to return array of channels for web
export const getChannels = availableChannels => {
  return availableChannels.filter(obj => obj.channel === ChannelManager.channel);
};

// method to check today's day
export const checkTodayDay = (isOpen, operatingHours) => {
  let storeTimeMsg = '';
  let dayName = '';
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'EVERYDAY'];
  const newDate = new Date();
  if (isOpen) {
    dayName = days[newDate.getDay()].toUpperCase();
    operatingHours.length &&
      // eslint-disable-next-line array-callback-return
      operatingHours.filter(obj => {
        if (obj.day.toUpperCase() === dayName.toUpperCase()) {
          storeTimeMsg =
            parseInvalidDate(obj.availableHours.to, 'hhmm')?.minute() === 0
              ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj.availableHours.to,
                  'hhmm',
                  'hA',
                )
                  ?.split(' ')
                  .join('')}`
              : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj?.availableHours?.to,
                  'hhmm',
                  'h:mmA',
                )
                  ?.split(' ')
                  .join('')}`;
        } else if (obj.day.toUpperCase() === SEARCH_STORE_CONSTANTS.EVERYDAY) {
          storeTimeMsg =
            parseInvalidDate(obj.availableHours.to, 'hhmm')?.minute() === 0
              ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj?.availableHours?.to,
                  'hhmm',
                  'hA',
                )
                  ?.split(' ')
                  .join('')}`
              : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj.availableHours.to,
                  'hhmm',
                  'h:mmA',
                )
                  ?.split(' ')
                  .join('')}`;
        }
      });
  } else {
    if (newDate.getDay() + 1 >= days.length) {
      dayName = days[newDate.getDay() + 1 - days.length].toUpperCase();
    } else {
      dayName = days[newDate.getDay() + 1].toUpperCase();
    }
    // eslint-disable-next-line array-callback-return
    operatingHours.filter(obj => {
      if (obj.day.toUpperCase() === dayName.toUpperCase()) {
        storeTimeMsg =
          parseInvalidDate(obj.availableHours.from, 'hhmm')?.minute() === 0
            ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj?.availableHours?.from,
                'hhmm',
                'hA',
              )
                ?.split(' ')
                .join('')}`
            : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj.availableHours.from,
                'hhmm',
                'h:mmA',
              )
                ?.split(' ')
                .join('')}`;
      } else if (obj.day.toUpperCase() === SEARCH_STORE_CONSTANTS.EVERYDAY) {
        storeTimeMsg =
          parseInvalidDate(obj.availableHours.from, 'hhmm')?.minute() === 0
            ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj.availableHours.from,
                'hhmm',
                'hA',
              )
                ?.split(' ')
                .join('')}`
            : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj?.availableHours?.from,
                'hhmm',
                'h:mmA',
              )
                ?.split(' ')
                .join('')}`;
      }
    });
  }

  return storeTimeMsg;
};
/**
 * Method to get address by passing lat long values
 */
export const getAddressByGeoCode = latlng => {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          resolve(results[0].formatted_address);
        } else {
          console.log('No results found');
          reject(results);
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
        reject(results);
      }
      resolve(results[0].formatted_address);
    });
  });
};

export const callUtilFunct = (dispositionType, eligibleStoreDataList) => {
  const serviceArray = [];
  const closedStores = [];
  let checkForWEBChannel;
  let DISPOSITION_TYPE_SERVICE;
  let errorMsg;
  let errorMsgWithCTA;

  switch (dispositionType.toLowerCase()) {
    case 'delivery':
      DISPOSITION_TYPE_SERVICE = DELIVERY_SERVICE;
      errorMsg = translateWithI18Next(DELIVERY_UNAVAILABLE);
      errorMsgWithCTA = translateWithI18Next(DELIVERY_UNAVAILABLE);
      break;
    case 'takeaway':
      errorMsg = translateWithI18Next(TAKEAWAY_UNAVAILABLE);
      DISPOSITION_TYPE_SERVICE = translateWithI18Next(TAKE_AWAY_SERVICE);
      errorMsgWithCTA = translateWithI18Next(TAKEAWAY_UNAVAILABLE_WITH_CTA);
      break;
    case 'dine-in':
      DISPOSITION_TYPE_SERVICE = DINE_IN_SERVICE;
      errorMsg = translateWithI18Next(DINEIN_UNAVAILABLE);
      errorMsgWithCTA = translateWithI18Next(DINEIN_UNAVAILABLE);
      break;
    // no default
  }

  eligibleStoreDataList?.forEach((item, index) => {
    let isOpenSTORE = item?.summary?.status?.currentStatus !== STORE_STATUS_OPEN;
    isOpenSTORE && closedStores.push(item);
    if (item?.availableServicesPerChannel) {
      checkForWEBChannel = item?.availableServicesPerChannel?.filter(
        ({ channel }) => channel === ChannelManager.channel,
      );
      let el = checkForWEBChannel?.length ? checkForWEBChannel[0]?.services : [];
      const notDispositionService = !el.includes(DISPOSITION_TYPE_SERVICE);
      notDispositionService && el.length && serviceArray.push(item);
    }
  });

  return eligibleStoreDataList?.length === serviceArray?.length
    ? errorMsg
    : !checkForWEBChannel?.length ||
        (eligibleStoreDataList?.length === closedStores?.length && errorMsgWithCTA) ||
        null;
};

export const compareDname = (storeOne, storeTwo) => {
  if (storeOne.name[0].value < storeTwo.name[0].value) {
    return -1;
  }
  if (storeOne.name[0].value > storeTwo.name[0].value) {
    return 1;
  }
  return 0;
};

export const deliveryModuleAnalyticFn = (locationAlert, negativeScenarioAlert) => {
  if (getAndCheck(locationAlert, !negativeScenarioAlert)) {
    getEmbeddedErrorMessageAnalytic(
      translateWithI18Next(SEARCHSTORE_MODULE_DATA.contentData.deliveryHeaderText),
      translateWithI18Next(LOCATION_WARNING_MESSAGE),
    );
  }
};

/* check whether coordinates are received for location or not */
export const checkSuccessOrError = (coordinates, success, error) => {
  if (coordinates) {
    success();
  } else {
    error();
  }
};

export const getStyles = (isMapView, isMobile) => {
  if (isMapView && isMobile) {
    return {
      margin: '0',
      position: 'absolute',
      'max-height': '325px',
      bottom: '0',
    };
  }
};

export const getMiles = mi => {
  return (parseFloat(mi) / 1600).toFixed(1) + ' mi';
};

export const getKms = mtr => {
  return (parseFloat(mtr) / 1000).toFixed(1) + ' ' + KMS;
};

export const orderItems = (orderTypesObj, collectionPoint) => {
  const updatedorderTypesObj = [...orderTypesObj].reverse();
  return (
    !!isEmptyObject(updatedorderTypesObj) &&
    updatedorderTypesObj.map((item, index) => {
      const collectionPointTxt = collectionPoint
        ? `${translateWithI18Next(item)} (${collectionPoint})`
        : `${translateWithI18Next(item)}`;

      return (
        <div className='store-card-order-option store-card-order-option-data' key={index}>
          <span>
            <ImageComponent data-testid='' srcFile={GreenTickImage} alt={'✓'} className='tick-image' />
          </span>
          <span>{item === PICK_UP ? `${collectionPointTxt}` : translateWithI18Next(item)}</span>
        </div>
      );
    })
  );
};

export const isOpenButtonClass = isOpen => {
  return isOpen ? 'redButton' : 'whiteButton';
};

export const isOpenButtonText = (isFindAKFC, isOpen, storeOpenBtnText) => {
  if (isFindAKFC) {
    return isOpenFindAKFCButtonText(isOpen);
  } else {
    return isOpen ? translateWithI18Next(storeOpenBtnText) : translateWithI18Next(BROWSE_MENU_TEXT);
  }
};

export const orderNow = (isFindAKFC, isOpen) => {
  if (isFindAKFC) {
    return isOpenFindAKFCButtonText(isOpen);
  } else {
    return isOpen
      ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.ORDER_NOW)}`
      : translateWithI18Next(BROWSE_MENU_TEXT);
  }
};

export const isOpenFindAKFCButtonText = isOpen => {
  return isOpen ? translateWithI18Next(START_ORDER) : translateWithI18Next(BROWSE_MENU_TEXT);
};

export const hoursText = service => {
  return isDeliveryDisposition(service) ? translateWithI18Next(DELIVERY_HRS) : translateWithI18Next(TRADING_HRS);
};

export const hourService = (dispositionService, storeTiming) => {
  let storeTimingData = [];
  if (isPickupDisposition(dispositionService) || isCateringDisposition(dispositionService)) {
    storeTimingData = storeTiming?.storeTradingHours;
  } else if (isDeliveryDisposition(dispositionService)) {
    storeTimingData = storeTiming?.storeDeliveryHours;
  }
  return { storeTimingData };
};
//convert 2330 to 11:30 pm
export const convertTimeString = (time, format = DATE_FORMATS.H_MM_AA) => {
  const [hour, minute] = time.split(':');
  return convertTwentyFourHrToTwelveHr(hour, minute, format);
};
//Get RDP icons
export const getRDPIcons = (restrauntContentful, restarantDetails) => {
  const channelWithUrl = [];
  restarantDetails?.map(itemChannel => {
    restrauntContentful?.map((item, index) => {
      const { fields } = item;
      const { category, mobileImage, desktopImage } = fields;
      if (get(itemChannel, 'channel', '') === category) {
        channelWithUrl.push({
          key: index,
          linkUrl: get(itemChannel, 'linkUrl', ''),
          desktopImage: get(desktopImage, 'fields.file.url', ''),
          mobileImage: get(mobileImage, 'fields.file.url', ''),
          channelName: itemChannel?.channel,
        });
        return channelWithUrl;
      }
      return channelWithUrl;
    });
    return channelWithUrl;
  });
  return channelWithUrl;
};

export const capitalizeDisposition = param => {
  const replaceHyphen = param?.replace('-', '');
  return translateWithI18Next(replaceHyphen);
};
export const formatDisposition = param => {
  return param?.reverse().join(', ');
};

export const deliveryOptionData = param => {
  return param?.reduce((obj, item) => {
    if (
      item?.channel !== SEARCH_STORE_CONSTANTS.SERVICE_CHANNEL_APP &&
      item?.channel !== SEARCH_STORE_CONSTANTS.SERVICE_CHANNEL
    ) {
      const channelName = capitalizeDisposition(item?.channel);
      obj.push(channelName);
    }
    return obj;
  }, []);
};

export const aminityFeatureData = param => {
  return param?.map(option => {
    return {
      [SEARCH_STORE_CONSTANTS.TYPE]: SEARCH_STORE_CONSTANTS.LOCATION_FEATURE_SPECIFICATION,
      [SEARCH_STORE_CONSTANTS.NAME]: option,
    };
  });
};

export const collectionPointData = (item, seoOptions) => {
  const collectionPointName = item?.filter(
    itm => itm?.channel === Config.VIEWPORT_AGENT_BROWSER && itm?.service === PICK_UP,
  )[0];
  const collectionName = collectionPointName?.collectionPoints.map(ctx => {
    return capitalizeDisposition(ctx?.name);
  });
  if (seoOptions === SEO_OPTIONS) {
    return collectionName;
  }
  return formatDisposition(collectionName);
};

export const callNearStore = nearStore => {
  let phoneNumber = '';
  if (isEmptyObject(nearStore)) {
    const { contacts = [] } = nearStore;
    contacts.forEach(c => {
      const { key, value } = c;
      if (key === PHONE_NUMBER) {
        phoneNumber = value;
      }
    });
  }
  return phoneNumber;
};
// convert phoneNo. 0292833915 to (02) 9283 3915
export const formatNumber = (number, storeNumberFormat) => {
  return number?.replace(/\D+/g, '').replace(storeNumberFormat, '($1) $2 $3');
};

export const getStoreDetails = (store, findAKFC, collectionPoint, dispositionType) => {
  const storeDistance = store?.distance?.distance?.value;
  const checkIfDistanceisZero = storeDistance === 0 ? storeDistance.toString() : storeDistance;
  const distanceValOrString = checkIfDistanceisZero || store?.distance;
  //call to extract web channels
  const channels = store?.availableServicesPerChannel;
  const webChannel = channels && getChannels(channels);
  const { services: orderTypes = [] } = getValueOrDefaultArray(webChannel?.[0]);

  // check store is open or closed
  const isOpen = store?.summary?.status?.currentStatus === STORE_STATUS_OPEN;
  const timimgMsg = showStoreTimingMsg(store, dispositionType);

  return {
    storeOpen: isOpen,
    storeName: getValueOrEmptyString(store?.name?.[0]?.value),
    storeMile: getKms(distanceValOrString) ?? '',
    availabilityLevel: store?.specialEventItemAvailabilityLevel,
    storeAddress: getFullAddressDetails(getValueOrDefaultObject(store?.addresses?.[0]?.address)) ?? '',
    storeOrderTypes: orderItems(orderTypes, collectionPoint) ?? '',
    storeTimeMessage: timimgMsg ?? '',
    dispositionService: store?.service,
    storeTiming: store?.summary?.timings,
  };
};

/* sort open and closed stores */
const sortOpenClosedStores = allStores => {
  const openStores = allStores?.filter(item => item?.summary?.status?.currentStatus === STORE_STATUS_OPEN);
  const closedStores = allStores?.filter(item => item?.summary?.status?.currentStatus !== STORE_STATUS_OPEN);
  return [...openStores, ...closedStores];
};
/* logic for store card display in sequence */
export const sortStoresForDisplay = (
  eligibleStoresList,
  favouriteStores,
  STORE_CARD_LIMIT,
  showStores,
  shouldShowStoresList,
) => {
  if (showStores && shouldShowStoresList) {
    const favIdList = favouriteStores?.map(item => item?.storeBasic?.id);
    const favEligibleStores = eligibleStoresList
      ?.filter(item => favIdList?.includes(item?.id))
      ?.map(item => {
        return { ...item, isFavoriteStore: true };
      });
    const nonFavEligibleStores = eligibleStoresList?.filter(item => !favIdList?.includes(item?.id));
    const favSortedStores = sortOpenClosedStores(favEligibleStores);
    const nonFavSortedStores = sortOpenClosedStores(nonFavEligibleStores);
    return [...favSortedStores, ...nonFavSortedStores].slice(0, STORE_CARD_LIMIT);
  } else {
    return [];
  }
};

/* check for type of text to be shown (list/map) */
export const typeOfViewText = isMapView => {
  return isMapView ? { text: LIST_TEXT, imageSrc: Union } : { text: MAP_TEXT, imageSrc: MapImage };
};

// add store to favourite
export const addStoreToFavoriteHandler = (store, dispatch, tenantId, customerId) => {
  dispatch(
    setStoresFavourite({
      data: {
        storeId: store?.id,
      },
      tenantId: tenantId,
      customerId: customerId,
      dispatch: 'dispatch',
    }),
  );
};

// remove store from favourite
export const removeStoreFromFavoriteHandler = (store, dispatch, tenantId, customerId) => {
  dispatch(
    setStoresUnFavourite({
      tenantId: tenantId,
      customerId: customerId,
      storeId: store?.id,
      dispatch: 'dispatch',
    }),
  );
};

// Method to handle seselectStoreClickHandlerarch store card button click
export const selectStoreClickHandler = orderNowObject => {
  const { event, store, userOrderStateDispatch, userOrderState, flag } = orderNowObject;
  event.preventDefault();
  event.stopPropagation();
  if (userOrderState?.reorderFlag?.fromReorderFlag) {
    if (flag === SEARCH_STORE_CONSTANTS.ORDER_NOW) {
      userOrderStateDispatch({
        type: SET_ORDER_NOW_REORDER,
        value: { showOrderNowReorder: true },
      });
      userOrderStateDispatch({
        type: SET_SCHEDULE_ORDER_REORDER,
        value: { showScheduleOrderReorder: false },
      });
      userOrderStateDispatch({ type: SCHEDULE_ORDER_CLICKED, value: { scheduleModeClicked: false } });
    }
    if (flag === SEARCH_STORE_CONSTANTS.ORDER_SCHEDULE) {
      userOrderStateDispatch({ type: CHANGE_SCHEDULE_MODE, value: { changeScheduleModeFlag: false } });
      userOrderStateDispatch({ type: SCHEDULE_ORDER_CLICKED, value: { scheduleModeClicked: true } });
      userOrderStateDispatch({
        type: SET_ORDER_NOW_REORDER,
        value: { showOrderNowReorder: false },
      });
      userOrderStateDispatch({
        type: SET_SCHEDULE_ORDER_REORDER,
        value: { showScheduleOrderReorder: true },
      });
      userOrderStateDispatch({ type: SHOW_SCHEDULE_DISPOSITION, value: { showSheduleDisposition: true } });
    }
    userOrderStateDispatch({ type: REORDER_MODAL_OPEN, value: { reorderModalOpen: true } });
    userOrderStateDispatch({ type: REORDER_STORE_CHANGED, value: { storeChanged: true } });
  } else {
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_DATA,
      value: { showScheduleOrderCatering: true, store: store },
    });
    if (flag === SEARCH_STORE_CONSTANTS.ORDER_NOW) {
      userOrderStateDispatch({
        type: SET_ORDER_NOW,
        value: { showOrderNow: true },
      });
      userOrderStateDispatch({
        type: SET_SCHEDULE_ORDER,
        value: { showScheduleOrder: false },
      });
      userOrderStateDispatch({ type: SCHEDULE_ORDER_CLICKED, value: { scheduleModeClicked: false } });
    }
    if (flag === SEARCH_STORE_CONSTANTS.ORDER_SCHEDULE) {
      userOrderStateDispatch({ type: CHANGE_SCHEDULE_MODE, value: { changeScheduleModeFlag: false } });
      userOrderStateDispatch({ type: SCHEDULE_ORDER_CLICKED, value: { scheduleModeClicked: true } });
      userOrderStateDispatch({
        type: SET_ORDER_NOW,
        value: { showOrderNow: false },
      });
      userOrderStateDispatch({
        type: SET_SCHEDULE_ORDER,
        value: { showScheduleOrder: true },
      });
      userOrderStateDispatch({ type: SHOW_SCHEDULE_DISPOSITION, value: { showSheduleDisposition: true } });
    }
  }
  userOrderStateDispatch({
    type: SEARCH_ORDER_DATA,
    value: { showSearchStore: false },
  });
  // update currently selected store info
  localStorage.setItem(CURRENT_STORE_INFO_STORAGE, JSON.stringify(store));

  if (flag === SEARCH_STORE_CONSTANTS.ORDER_NOW) {
    userOrderStateDispatch({
      type: SET_ORDER_NOW,
      value: { showOrderNow: true },
    });
    userOrderStateDispatch({
      type: SET_SCHEDULE_ORDER,
      value: { showScheduleOrder: false },
    });
    userOrderStateDispatch({ type: SCHEDULE_ORDER_CLICKED, value: { scheduleModeClicked: false } });
  }
  if (flag === SEARCH_STORE_CONSTANTS.ORDER_SCHEDULE) {
    userOrderStateDispatch({ type: CHANGE_SCHEDULE_MODE, value: { changeScheduleModeFlag: false } });
    userOrderStateDispatch({ type: SCHEDULE_ORDER_CLICKED, value: { scheduleModeClicked: true } });
    userOrderStateDispatch({
      type: SET_ORDER_NOW,
      value: { showOrderNow: false },
    });
    userOrderStateDispatch({
      type: SET_SCHEDULE_ORDER,
      value: { showScheduleOrder: true },
    });
    userOrderStateDispatch({ type: SHOW_SCHEDULE_DISPOSITION, value: { showSheduleDisposition: true } });
  }

  userOrderStateDispatch({
    type: CURRENT_STORE_INFO,
    value: store,
  });
  userOrderStateDispatch({ type: FAV_STORE_NOT_IN_RADIUS, value: { showNotInRadiusError: false } });
};

const getDispositionNew = userOrderStateVal =>
  isEmptyEvery(userOrderStateVal.dispositionNew)
    ? JSON.parse(localStorage.getItem(DISPOSITION_NEW))
    : userOrderStateVal.dispositionNew;

const getDispositionType = dispositionStateVal => dispositionStateVal || {};

const getSelectedDispositionVal = dispositionVal =>
  dispositionVal ? dispositionVal?.toUpperCase() : localStorage.getItem(DISPOSITION_TYPE)?.toUpperCase();

const getCateringPathName = dispositionVal => ({
  pathname: `${isCateringDisposition(dispositionVal) ? CATERING_PAGE : MENU_PAGE}`,
});

const getDispatchAction = ({
  basketId,
  foodLines,
  dispositionNew,
  selectedDispositionValue,
  selectedStoreInfo,
  scheduleOrderData,
  dispatch,
  tenantId,
  obj,
  dateTimeObj,
}) => {
  if (basketId && foodLines?.length > 0) {
    if (
      dispositionNew?.type?.toUpperCase() !== selectedDispositionValue ||
      dispositionNew?.store?.id !== selectedStoreInfo?.id ||
      dispositionNew?.deliveryAddress !== scheduleOrderData?.deliveryAddress ||
      dispositionNew?.deliveryAddressLine !== scheduleOrderData?.deliveryAddressLine
    ) {
      dispatch?.(
        updateStoreWithDisposition(
          {
            tenantId,
            basketId,
            postBody: {
              storeID: selectedStoreInfo?.id,
              service: selectedDispositionValue?.toLowerCase(),
              deliveryAddress: obj?.deliveryAddress,
              requestedDateTime: obj?.requestedDateTime,
            },
          },
          () => {
            dispatch(
              orderReadyDateTimeUpdate({
                params: { basketId: basketId },
                tenantId: tenantId,
                postBody: obj?.requestedDateTime,
              }),
            );
          },
        ),
      );
    }
    if (
      dispositionNew?.type?.toUpperCase() === selectedDispositionValue &&
      dispositionNew?.store?.id === selectedStoreInfo?.id &&
      dispositionNew?.deliveryAddress === scheduleOrderData?.deliveryAddress &&
      dispositionNew?.deliveryAddressLine === scheduleOrderData?.deliveryAddressLine &&
      (dispositionNew?.selectedTime !== dateTimeObj.selectedTime || dispositionNew?.date !== dateTimeObj?.date)
    ) {
      dispatch(
        orderReadyDateTimeUpdate({
          params: { basketId: basketId },
          tenantId: tenantId,
          postBody: obj?.requestedDateTime,
        }),
      );
    }
  } else {
    dispatch(createBasketsAction({ data: obj, tenantId: tenantId }));
  }
};

export const navigateToMenuPage = async (
  userOrderStateDispatch,
  history,
  dispatch,
  { tenantId, userParams, userOrderState, store: selectedStoreInfo, basketId, foodLines },
) => {
  const dispositionNew = getDispositionNew(userOrderState);
  const { dispositionStateData, scheduleOrderData } = userOrderState;
  const { dispositionType = PICK_UP } = getDispositionType(dispositionStateData);
  const selectedDispositionValue = getSelectedDispositionVal(dispositionType);

  const payload = {
    storeID: selectedStoreInfo?.id,
    disposition: selectedDispositionValue,
    postCode: selectedStoreInfo?.addresses?.[0]?.address?.pinCode,
  };
  setLocalisationFinishAnalyticsData(payload);
  userOrderStateDispatch({
    type: SCHEDULE_ORDER_DATA,
    value: {
      showScheduleOrderCatering: false,
      selectedStoreInfo: selectedStoreInfo,
    },
  });
  userOrderStateDispatch({
    type: DISPOSITION_METHOD,
    value: { dispositionType: selectedDispositionValue },
  });

  localStorage.setItem(DISPOSITION_TYPE, selectedDispositionValue);
  localStorage.setItem(STORE_INFO, JSON.stringify(selectedStoreInfo));
  localStorage.setItem(CURRENT_STORE_INFO_STORAGE, JSON.stringify(selectedStoreInfo));
  userOrderStateDispatch({
    type: CURRENT_STORE_INFO,
    value: getValueOrDefaultObject(selectedStoreInfo),
  });
  userOrderStateDispatch({ type: SEARCH_ORDER_DATA, value: { showSearchStore: false } });
  userOrderStateDispatch({
    type: SET_FORCERELOAD,
    value: { forceReload: true },
  });
  let dateTimeObj;
  if (isCateringDisposition(dispositionType)) {
    const dateTimeSlots = getDateTimeList(selectedStoreInfo);
    const enabledDate = dateTimeSlots?.find(item => !item?.date?.disabled);
    dateTimeObj = {
      date: enabledDate?.date?.key,
      selectedTime: enabledDate?.timeSlots?.[0]?.value,
      time: enabledDate?.timeSlots?.[0]?.value,
    };
  } else {
    dateTimeObj = {
      selectedTime: ASAP_TIMING,
      time: ASAP_TIMING,
      date: formatDateTime(new Date(), DATE_FORMATS.YYYY_MM_DD),
      epochTime: ASAP_TIMING

    };
  }
  if (
    recursiveOrCheck(
      dispositionNew === null,
      dispositionNew?.type?.toUpperCase() !== selectedDispositionValue,
      dispositionNew?.store?.id !== selectedStoreInfo?.id,
      dispositionNew?.deliveryAddress !== scheduleOrderData?.deliveryAddress,
      dispositionNew?.deliveryAddressLine !== scheduleOrderData?.deliveryAddressLine,
      dispositionNew?.selectedTime !== dateTimeObj.selectedTime,
      dispositionNew?.date !== dateTimeObj?.date,
    )
  ) {
    const obj = await createBasketData(
      selectedStoreInfo,
      getDispositionTypeOnAPIFormat(selectedDispositionValue),
      '',
      dateTimeObj,
      scheduleOrderData?.deliveryAddress,
      scheduleOrderData?.deliveryAddressLine,
    );
    getDispatchAction({
      basketId,
      foodLines,
      dispositionNew,
      selectedDispositionValue,
      selectedStoreInfo,
      scheduleOrderData,
      dispatch,
      tenantId,
      obj,
      dateTimeObj,
    });
    const params = {
      dispatch,
      userOrderStateDispatch,
      dispositionNew,
      selectedDispositionValue,
      currentStoreInfoData: selectedStoreInfo,
      dateTimeObj,
      scheduleOrderData,
      history,
      redirectionOrder: null,
      userParams,
      dispositionType,
      fromflag: BROWSE_MENU,
      obj,
    };
    if (dateTimeObj.date !== ASAP && dateTimeObj.time !== ASAP) {
      const { storeId, channel, service, requestedDateTime } = obj;
      const payloadData = {
        storeid: storeId,
        channel,
        service,
        futureDateTimeInEpochFormat: requestedDateTime?.futureDateTime,
      };
      dispatch(
        getStoreStatusForTimeSaga(
          { data: payloadData, tenantId: tenantId },
          () => successCallbackHandler(params),
          null,
        ),
      );
    } else {
      resetMenuFulfillmentData();
      successCallbackHandler(params);
    }
  }
  history?.push?.(getCateringPathName(dispositionType));
};

export const handleSkipStoresListScreen = (
  eligibleStoresList,
  shouldShowSingleStore,
  address,
  deliveryAddressLineVal,
  setShowStores,
  isMapView,
  userOrderStateDispatch,
  userOrderState,
  autoSelectDeliveryStore = false,
  dispositionType = '',
) => {
  const openStores = eligibleStoresList?.filter(store => store?.summary?.status?.currentStatus === STORE_STATUS_OPEN);
  const storeListcheck = autoSelectDeliveryStore
    ? openStores?.length > 0 && dispositionType?.toUpperCase() === DISPOSITION_KEYS.DELIVERY
    : openStores?.length === 1;
  if (storeListcheck && !shouldShowSingleStore) {
    if (userOrderState?.reorderFlag?.fromReorderFlag) {
      userOrderStateDispatch({ type: REORDER_MODAL_OPEN, value: { reorderModalOpen: true } });
      userOrderStateDispatch({ type: REORDER_STORE_CHANGED, value: { storeChanged: true } });
    } else {
      userOrderStateDispatch({
        type: SCHEDULE_ORDER_DATA,
        value: {
          deliveryAddress: address,
          deliveryAddressLine: deliveryAddressLineVal,
          showScheduleOrderCatering: true,
          selectedStoreInfo: openStores[0],
        },
      });
    }
    userOrderStateDispatch({ type: SEARCH_ORDER_DATA, value: { showSearchStore: false } });
    localStorage.setItem(CURRENT_STORE_INFO_STORAGE, JSON.stringify(openStores[0]));
    userOrderStateDispatch({
      type: CURRENT_STORE_INFO,
      value: openStores[0],
    });
  } else {
    setShowStores(!isMapView);
    userOrderStateDispatch({
      type: SCHEDULE_ORDER_DATA,
      value: {
        deliveryAddress: address,
        deliveryAddressLine: deliveryAddressLineVal,
        showScheduleOrderCatering: false,
      },
    });
  }
};

/* called when start order is clicked from saved adresses */
export const startOrderButtonClickHandler = (
  savedAddress,
  userOrderStateDispatch,
  handleSelectAddress,
  dispatch,
  setPWAstorage,
) => {
  dispatch(setPWAstorage({ key: SELECTED_FAV_ADDRESS, value: savedAddress }));
  const finalAddress =
    savedAddress?.addressLines[1] +
    ', ' +
    savedAddress?.city +
    ', ' +
    savedAddress?.state +
    ', ' +
    savedAddress?.country;
  userOrderStateDispatch({
    type: SEARCH_ORDER_DATA,
    value: { showSearchStore: true, configSearchScreen: SAVED },
  });
  localStorage.setItem(CONFIG_SEARCH_SCREEN, SAVED);
  handleSelectAddress(finalAddress, {});
  setLocalStorage(finalAddress);
  dispatchAddressLineVal(dispatch, setPWAstorage, savedAddress);
};

export const dispatchAddressLineVal = (dispatch, setPWAstorage, savedAddress) => {
  dispatch(setPWAstorage({ key: IS_FAV_ADDRESS_SELECTED, value: true }));
  dispatch(setPWAstorage({ key: SELECTED_LINE_ADDRESS_VAL, value: savedAddress?.addressLines[0] }));

  dispatch(setPWAstorage({ key: FORMATTED_DELIVERY_ADDRESS, value: savedAddress?.addressLines[1] }));
};

export const getAddressTempValue = (placeId, shouldHandleKeyPress, suggestedAddress, value, dispositionType) => {
  if (!placeId && shouldHandleKeyPress && dispositionType?.toUpperCase() === DELIVERY) {
    return suggestedAddress?.[0]?.description;
  } else {
    return value;
  }
};

export const autoFillAddress = (
  scheduleOrderData,
  searchStoreData,
  suggestedAddress,
  setSuggestedAddress,
  handleSelectAddress,
  retainAddress,
  address,
) => {
  if (!scheduleOrderData?.changeFlow && searchStoreData?.searchedPlaceNonLocalized) {
    !suggestedAddress?.length && setSuggestedAddress([{ description: searchStoreData?.searchedPlaceNonLocalized }]);
    handleSelectAddress(searchStoreData?.searchedPlaceNonLocalized, {});
  } else if (retainAddress && !address) {
    setSuggestedAddress([{ description: retainAddress }]);
    handleSelectAddress(retainAddress, {}, {});
  }
};
/* check if there are zero-suggestions while searching address */
export const checkForZeroSuggestions = (status, ZERO_RESULTS, setSuggestedAddress) => {
  if (status === ZERO_RESULTS) {
    setSuggestedAddress([]);
  }
};

/* check which error to show based on store closed behaviour & zero suggestions while searching address */
export const typeOfClosedStoresError = (
  zeroSuggestionCloseStore,
  zeroSuggestionStoreCloseTrue,
  zeroSuggestionStoreCloseFalse,
  setErrorObj,
) => {
  if (zeroSuggestionCloseStore) {
    setErrorObj(zeroSuggestionStoreCloseTrue);
  } else {
    setErrorObj(zeroSuggestionStoreCloseFalse);
  }
};
/* check whether user has granted location access or not  */
export const checkLocationAccess = (userOrderStateDispatch, showLocationConfirmModal) => {
  const dispatchLocationFlag = flag => {
    userOrderStateDispatch({
      type: SHOW_LOCATION_CONFIRM_MODAL,
      value: { showLocationConfirmModal: flag },
    });
  };
  if (window.navigator && showLocationConfirmModal) {
    window.navigator.geolocation?.getCurrentPosition(
      () => dispatchLocationFlag(false),
      error => dispatchLocationFlag(error?.code === error?.PERMISSION_DENIED),
    );
  }
};

export const getLocationAccessStatus = (userOrderStateDispatch, resultsflag) => {
  const geoNav = navigator;
  const geoLoc = geoNav?.geolocation;
  if (!isSafari()) {
    geoNav?.permissions
      ?.query({
        name: GEOLOCATIOM_TEXT,
      })
      .then(function (result) {
        if (result.state === PROMPT_TEXT) {
          locationAllow(userOrderStateDispatch);
        } else {
          locationNotAllow(userOrderStateDispatch, resultsflag);
        }
      });
  } else {
    geoLoc?.getCurrentPosition(
      i => locationAllow(userOrderStateDispatch),
      i => locationNotAllow(userOrderStateDispatch, resultsflag),
    );
  }
};
/* return global search store className */
export const getSearchStoreGlobalCall = showLocationConfirmModal => {
  return `search-store-global-content ${showLocationConfirmModal ? 'inline-error' : ''}`;
};
/* return modified error msg when location access is blocked */
export const modifyLocationError = (LOCATION_BLOCKED_ERROR, KFC_URL) => {
  const locationError = translateWithI18Next(LOCATION_BLOCKED_ERROR);
  const kfcUrl = translateWithI18Next(KFC_URL);
  const kfcUrlHtml = `<b>${kfcUrl}</b>`;
  const replacedMsg = locationError?.replace('%kfcUrl%', kfcUrlHtml);
  return parse(String(replacedMsg));
};

export const getLocalisationErrorAnalytic = async (errorType = 'NA') => {
  await AnalyticsService.registerEvent(
    events?.localisationError?.name,
    {
      data: {
        event: LOCALISATION_ERROR,
        errorType,
      },
    },
    getDataLayer,
  );
};

export const localisationErrorAnalytic = (fireGoogleApiErrorAnalytic, googleApiErrorEventFire) =>
  isSupportedTenantCode() &&
  fireGoogleApiErrorAnalytic &&
  !googleApiErrorEventFire &&
  getLocalisationErrorAnalytic(API_ERROR);

export const modifyAddressSpacing = str => {
  if (str.includes(',')) {
    return str.replace(',', ', ');
  } else {
    return str;
  }
};

/* method to close keyboard on pressing enter in search box */
export const onEnterCloseKeyboard = (shouldHandleKeyPress, isKeyCodeEnter, inputRef, userOrderStateDispatch) => {
  userOrderStateDispatch({ type: ORDER_SUMMARY_MODAL_CLOSE, orderSummaryModalClose: true });
  shouldHandleKeyPress && isKeyCodeEnter && inputRef?.current?.blur();
};

export const showStoreTimingMsg = (store, dispositionType) => {
  const service = dispositionType || store?.service;
  const storeTimeMsg = checkTimeDuration(
    store?.summary,
    config(STORE_CLOSING_SOON_MODAL_TIMING),
    null,
    STORE_MESSAGE,
    service,
  );
  return (
    <span
      data-testid='store-timing-status'
      className={storeTimeMsg?.timingMessageStatus === 'store-open' ? 'open-text' : 'closed-text'}
    >
      {storeTimeMsg?.timingMessageLong}
    </span>
  );
};

export const daysOrEverydayCheck = (optHrs, day) => {
  let checkedArray;
  if (optHrs?.[0]?.day === EVERYDAY) {
    checkedArray = optHrs;
  } else {
    checkedArray = optHrs?.filter(item => item?.day?.toUpperCase() === DAYS[day]?.toUpperCase());
  }
  return checkedArray;
};

/* set first suggested address on pressing enter in input box*/
export const populateFirstSuggestion = (
  shouldPopulateFirstSuggestion,
  isEnterPressed,
  suggestedAddress,
  setAddress,
  address,
) => {
  if (shouldPopulateFirstSuggestion) {
    isEnterPressed && suggestedAddress?.length > 0 && setAddress(suggestedAddress[0]?.description);
  } else if (isEnterPressed) {
    setAddress(address);
  }
};

/* return services details as per channel */
export const getStoreChannelsDetails = (store, collectionPoint, seoOptions) => {
  const channels = store?.availableServicesPerChannel;
  const webChannel = channels && getChannels(channels);
  const { services: orderTypes = [] } = getValueOrDefaultArray(webChannel?.[0]);
  if (seoOptions === SEO_OPTIONS) {
    return { orderTypes, collectionPoint };
  } else {
    return orderItems(orderTypes, collectionPoint) ?? '';
  }
};
export const toggleHoursView = id => {
  document.querySelector(`#view-${id}`).classList.toggle('hoursHidden');
  document.querySelector(`#hours-${id}`).classList.toggle('hoursHiddenArrow');
};
/**
 * Method to set analytics items in the data layer object for  orderLater scheduleorder
 */
export const orderLaterAnalyticsData = async (payload, selectedTime) => {
  selectedTime !== SELECTED_TIME &&
    (await AnalyticsService.registerEvent(
      events?.orderLater?.name,
      {
        '%event%': 'orderLater',
        '%disposition%': payload?.disposition,
      },
      getDataLayer,
    ));
};

export const locationAllow = userOrderStateDispatch => {
  userOrderStateDispatch({
    type: SHOW_LOCATION_CONFIRM_MODAL,
    value: { showLocationConfirmModal: Boolean(false) },
  });
};

export const locationNotAllow = (userOrderStateDispatch, resultsflag) => {
  userOrderStateDispatch({
    type: SHOW_LOCATION_CONFIRM_MODAL,
    value: { showLocationConfirmModal: Boolean(resultsflag) },
  });
};

export const sortPolygonsStores = (validatedNearBystoresData, userLocation) => {
  const modifiedStores = validatedNearBystoresData?.map(store => ({
    ...store,
    polygons: store?.polygons?.map(poly => ({ lat: Number(poly.lat), lng: Number(poly.long) })),
  }));
  return modifiedStores?.filter(
    store => filterStoreBasedOnPolygon(store.polygons, userLocation?.userLat, userLocation?.userLong) === true,
  );
};

export const getFloorNumber = results => {
  const selectedFloorNumber = [];
  results[0]?.address_components?.forEach(addressData => {
    const filterFloorNumber = addressData?.types?.filter(data => {
      return data === SUBPREMISE;
    });
    if (filterFloorNumber.length > 0) {
      return selectedFloorNumber.push(addressData);
    }
  });
  return selectedFloorNumber[0];
};

export const getFloorNumberSeparateAddress = (floorNumber, address) => {
  if (floorNumber) {
    const getFloorNumberValue = address.replace(`#${floorNumber}`, '');
    return `${floorNumber}, ${getFloorNumberValue}`;
  } else {
    return address;
  }
};

export const getUnitValue = addressTemp => {
  const selectedAddress = addressTemp?.split(',');
  const getAddressData = selectedAddress[0]?.split(' ');
  const getUnitIndex = getAddressData.indexOf(UNIT_BY_ADDRESS);
  if (getUnitIndex > 0) {
    return `${getAddressData[getUnitIndex]} ${getAddressData[getUnitIndex + 1]}`;
  } else {
    return `${getAddressData[getAddressData.length - 1].replace('#', '')}`;
  }
};

export const selctedAddressCheck = getPwaStorageData => {
  return getPwaStorageData?.ifFavAddressSelected
    ? getPwaStorageData?.selectedFavouriteStoreAddress?.addressLines[1]
    : getPwaStorageData?.NonFormattedAddress;
};

export const getStreetNumber = results => {
  const selectedStreetNumber = [];
  results[0]?.address_components?.forEach(addressData => {
    const filterStreetNumber = addressData?.types?.filter(data => {
      return data === STREET_NUMBER_FILTER;
    });
    if (filterStreetNumber.length > 0) {
      return selectedStreetNumber.push(addressData);
    }
  });
  return selectedStreetNumber[0];
};
